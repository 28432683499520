<template>
	<div>
		<v-btn outlined class="text-uppercase float-right white--text" color="grey" @click="addNextYear()" :disabled="disableEdit" v-if="canEdit">
			Volgende periode toevoegen
		</v-btn>
		<h4 class="mb-9">Prijzen van de afgelopen en huidige periodes</h4>
		<v-data-table
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				:headers="headers"
				:items="unit.prices"
				item-key="email"
		>
			<template v-slot:body="{ items }">
				<tbody>
				<tr v-for="item in items">
					<td class="text-xs-left">{{ item.startDate | formatDate }}</td>
					<td class="text-xs-left">{{ item.endDate | formatDate }}</td>
					<td class="text-xs-left">{{ item.deposit ? item.deposit.amount: '-'}}</td>
					<td class="text-xs-left">{{ item.rent ? item.rent.amount: '-' }}</td>
					<td class="text-xs-left">{{ item.rent ? item.rent.stayAmount: '-' }}</td>
					<td class="text-xs-left">{{ item.egwi ? item.egwi.amount: '-' }}</td>
					<td class="text-xs-left">{{ item.inventory ? item.inventory.amount: '-' }}</td>
					<td class="text-xs-left">{{ item.studentTax ? item.studentTax.amount: '-' }}</td>
					<td class="text-xs-right">
						<v-icon class="" @click="editItem(item)" :disabled="disableEdit" v-if="canEdit">
							mdi-pencil-outline
						</v-icon>
					</td>
					<td class="justify-center" v-if="canEdit">
						<v-icon @click="confirmDeleteDialogItem = {_id: {unit,  item}}, confirmDeleteDialog = true" class="" color="grey">
							mdi-delete
						</v-icon>
					</td>
				</tr>
				</tbody>
			</template>
		</v-data-table>

		<v-dialog v-model="dialog" v-if="dialog" max-width="700px" scrollable persistent>
			<price-edit :newStartDate="startDateForNewPrice" :recurringCompanies="recurringCompanies" :addPriceYear="addPriceYear" :subscriptionPlans="subscriptionPlans" :companies="companies" :editedItem="editedItem" @save="save" @close="close"></price-edit>
		</v-dialog>
		<v-dialog v-model="confirmDeleteDialog" v-if="canEdit" max-width="400px">
			<confirm-delete v-if="confirmDeleteDialog" :item="confirmDeleteDialogItem"
			                @cancel="confirmDeleteDialogItem = null; confirmDeleteDialog = false"
			                @delete="deleteYear"></confirm-delete>
		</v-dialog>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import PriceEdit from './price-edit';
	import ConfirmDelete from '@components/dialog/confirm-delete';
	import { GETTERS } from '@/account/account.constants';
	import { Roles } from '@/account/account.store';
	import { ACTIONS } from '../../units.constants';
	import api from '../../../../../services/backendApi';
	import { ACTIONS as TOAST_ACTIONS } from '../../../../../components/toast/toast.constants';
	import moment from 'moment';

	export default {
		name: "price-panel",
		components: { PriceEdit, ConfirmDelete },
		props: {
			unit: Object
		},
		data() {
			return {
				disableEdit: true,
				addPriceYear: false,
        startDateForNewPrice: null,
				basicUrl: process.env.VUE_APP_API_URL,
				subscriptionPlans: {},
				companies: {},
				recurringCompanies: {},
				confirmDeleteDialog: false,
				confirmDeleteDialogItem: null,
				dialog: false,
				editedIndex: -1,
				sortBy: 'year',
				sortDesc: true,
				editedItem: {
					name: '',
					rent: {
						status: false
					},
					deposit: {
						status: false
					},
					egwi: {
						status: false
					},
					inventory: {
						status: false
					},
					studentTax: {
						status: false
					},
				},
				headers: [
					{ text: 'Startdatum', value: 'startDate',sortable: false },
					{ text: 'Einddatum', value: 'endDate',sortable: false },
					{ text: 'Waarborg', value: 'rentalDeposit.price' ,sortable: false},
					{ text: 'Huur Nieuwkomer', value: 'rent.price', sortable: false },
					{ text: 'Huur Blijver', value: 'rent.stayPrice', sortable: false },
					{ text: 'EGWI', value: 'egwi.price', sortable: false },
					{ text: 'Plaatsbeschrijving', value: 'plaatsbeschrijving.price', sortable: false },
					{ text: 'Studendenten taks', value: 'studentTax.price', sortable: false },
					{ text: '', sortable: false, align: 'right', },
					{ text: '', sortable: false, align: 'right', },
				],
				rent: {
					visible: false,
				},
				paymentTypes: [
          { id: 'onetimetask', label: 'Eenmalige betaling' },
          { id: 'recurring', label: 'Domiciliëring' },
          { id: 'ongoingtask', label: 'Doorlopende opdracht' },
          { id: 'onetimepayment', label: 'Eenmalige betaling via Mollie' },
				]
			}
		},
		mounted() {
			const config = { headers: { 'Content-Type': 'application/json' } };
			api
				.get(`${this.basicUrl}/payments/ecurring`, config)
				.then(response => (
					this.subscriptionPlans = response.data.data,
					this.disableEdit = false
				))

			api
				.get(`${this.basicUrl}/owners/companies-owners?minimal=true`, config)
				.then(response => (
					this.companies = response.data.data
				))

			api
				.get(`${this.basicUrl}/companies?minimal=true`, config)
				.then(response => (
					this.recurringCompanies = response.data.data
				))
		},
		computed: {
			currentYear() {
				const now = new Date();
				const year = now.getFullYear();
				const month = now.getMonth();
				if (month < 7) {
					return `${year - 1}-${year}`
				} else {
					return `${year}-${year + 1}`;
				}
			},
			...mapGetters({
				getRole: GETTERS.ROLE,
				getRights: GETTERS.RIGHTS
			}),
			canEdit() {
				return this.getRights.UNITS_PRICES.toLowerCase() === 'edit'
			},

		},
		watch: {
			dialog(val) {
				val || this.close()
			},
		},
		methods: {
			...mapActions({
				makeToast: TOAST_ACTIONS.MAKE_TOAST,
			}),
			deleteYear(deletePrice) {
				api.delete(`${this.basicUrl}/units/${deletePrice._id.unit._id}/prices/${deletePrice._id.item._id}`).then(() => {
					this.makeToast({
						message: `Prijs werd succesvol verwijderd.`,
						type: 'success',
					});

						this.confirmDeleteDialog = false;
						this.confirmDeleteDialogItem = null;

						var index = this.unit.prices.map(x => {
							console.log(x._id);
							return x._id;
						}).indexOf(deletePrice._id.item._id);

						this.unit.prices.splice(index, 1);

				}, (error) => {
					this.makeToast({ message: error, type: 'error' });
				})
			},
			editItem(item) {
				this.addPriceYear = false;
				this.editedIndex = this.unit.prices.indexOf(item);
				this.editedItem = Object.assign(this.editedItem, item);

        this.editedItem.startDate = 	this.editedItem.startDate === (undefined || null) ? null : moment(String(this.editedItem.startDate)).format('YYYY-MM-DD');
        this.editedItem.endDate = 	this.editedItem.endDate === (undefined || null) ? null : moment(String(this.editedItem.endDate)).format('YYYY-MM-DD');

				this.dialog = true
			},
			close() {
				this.dialog = false;
				this.$nextTick(() => {
					this.editedItem = {
						name: '',
						rent: {
							status: false
						},
						deposit: {
							status: false
						},
						egwi: {
							status: false
						},
						inventory: {
							status: false
						},
						studentTax: {
							status: false
						}
					};
					this.editedIndex = -1
				})
			},
			addNextYear() {
				this.addPriceYear = true;
				this.dialog = true;
        const latestEndDate = this.getLatestPriceEndDate();
        this.startDateForNewPrice = moment(String(latestEndDate)).add(1,'day').format('YYYY-MM-DD');
				return
				// const lastYear = this.unit.prices.sort((a,b) => a.year < b.year ? 1 : -1)[0].year;
				// const newYear = lastYear.split('-')[1] + '-' + (parseInt(lastYear.split('-')[1]) + 1);
				// this.unit.prices.push({
				// 	year: newYear,
				// 	...this.editedItem
				// });
			},
      getLatestPriceEndDate(){
        const sortedPrices = this.unit.prices.sort((a, b) => {
          if (a.endDate < b.endDate) { return 1; }
          if (a.endDate > b.endDate) { return -1; }
          return 0;
        });
        return sortedPrices.length>0 ? sortedPrices[0].endDate : moment().format('YYYY-MM-DD');
      },
			saveDate(date) {
				if (!date) return null

				const [year, month, day] = date.split('-')
				return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
			},
			save(addPriceYear) {
				if (this.editedIndex > -1) {
					Object.assign(this.unit.prices[this.editedIndex], this.editedItem)
				} else {
					this.unit.prices.push(this.editedItem)
				}
				this.close();

				let data = {
					...this.editedItem
				}

				data.startDate = this.saveDate(this.editedItem.startDate);
				data.endDate = this.saveDate(this.editedItem.endDate);

				if ( addPriceYear === true ) {
					api.post(`${this.basicUrl}/units/${this.unit._id}/prices`, data).then(() => {
						this.makeToast({
							message: `Units prijzen werden aangepast`,
							type: 'success',
						});
					}, (error) => {
						this.makeToast({ message: error, type: 'error' });
					})
				} else {
					api.patch(`${this.basicUrl}/units/${this.unit._id}/prices/${data._id}`, data).then(() => {
						this.makeToast({
							message: `Units prijzen werden aangepast`,
							type: 'success',
						});
					}, (error) => {
						this.makeToast({ message: error, type: 'error' });
					})
				}

				return
			},
		}
	}
</script>

<style scoped>
	.container {
		margin-left: 0 !important;
	}

</style>
