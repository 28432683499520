<template>
	<v-card>
		<v-card-title>
			<span class="headline">Aanpassen/Toevoegen gebruiker</span>
		</v-card-title>

		<v-card-text v-if="editedItem">
			<v-container>
				<v-row>
					<v-col cols="6">
						<v-text-field
								outlined
								v-model="editedItem.firstName"
								label="Voornaam"
								required
						></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field
								outlined
								v-model="editedItem.lastName"
								label="Achternaam"
								required
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-text-field
								outlined
								v-model="editedItem.email"
								label="email address"
								required
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-select class="filter-select" outlined hide-details small
								  label="Rol"
								  :items="roles"
								  v-model="editedItem.role">
						</v-select>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>

		<v-card-actions class="d-flex justify-space-between">
			<v-btn
					color="red darken-1"
					text
					@click="$emit('delete', editedItem)"
			>
				Verwijderen
			</v-btn>


			<div>
				<v-btn
						color="blue darken-1"
						text
						@click="$emit('close')"
				>
					Cancel
				</v-btn>
				<v-btn
						color="blue darken-1"
						text
						@click="$emit('save', editedItem)"
				>
					Save
				</v-btn>
			</div>
		</v-card-actions>
	</v-card>
</template>

<script>
	export default {
		name: "user-edit",
		props: {
			editedItem: Object
		},
		data() {
			return {
				roles: ['COACH', 'SUPPORT', 'ADMIN','RENT']
			}
		},
		watch: {
			dialog(val) {
				val || this.close()
			},
		},
		methods: {
		}
	}
</script>

<style scoped>
	.container {
		margin-left: 0 !important;
	}

</style>
