<template>
	<div>
		<h4 class="mb-9">Eigenaars</h4>
		<v-data-table
				:headers="headers"
				:items="unit.owners"
				item-key="email"
		>
			<template v-slot:body="{ items }">
				<tbody>
				<tr v-for="item in items">
					<td class="text-xs-left">{{ item.startDate | formatDate }} </td>
					<td class="text-xs-left">{{ item.endDate | formatDate }} </td>
					<td class="text-xs-left">{{ item.name }} </td>
				</tr>
				</tbody>
			</template>
		</v-data-table>
	</div>
</template>

<script>

	export default {
		name: "company-panel",
		props: {
			unit: Object
		},
		data() {
			return {
				dialog: false,
				editedIndex: -1,
				sortBy: 'year',
				sortDesc: true,
				headers: [
					{ text: 'Startdatum'},
					{ text: 'Einddatum'},
					{ text: 'Eigenaar' },
				],
			}
		},
		methods: {
		},
	}
</script>

<style scoped>
	.container {
		margin-left: 0 !important;
	}

</style>
