import api from '../../../services/backendApi';

const unitsUrl = '/units';
const config = { headers: { 'Content-Type': 'application/json' } };

export default {
  getUnits(filter) {
    return api.get(`${unitsUrl}`, { filter }, config);
  },
  getRentUnits() {
    return api.get(`${unitsUrl}/get-rent-units`, config);
  },
  getUnitDetail(id) {
    return api.get(`${unitsUrl}/${id}`, config);
  },
  updateUnit(unit) {
    unit.prices.forEach(e => delete e['$id']);
    return api.patch(`${unitsUrl}/${unit._id}`, { unit }, config);
  },
  deleteUnit(unit) {
    return api.delete(`${unitsUrl}/${unit._id}`, config);
  },
  deleteUnitYear(unit) {
    var unit = unit._id;
    return api.delete(`${unitsUrl}/${unit.unit._id}/${unit.item.year}/${unit.item._id}`, config);
  },
  uploadData(file) {
    const formData = new FormData();
    formData.append('file', file, 'data.csv');
    return api.post(`${unitsUrl}/uploadData`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  uploadPricesData(file) {
    const formData = new FormData();
    formData.append('file', file, 'data.csv');
    return api.post(`${unitsUrl}/uploadPricesData`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  updateUnitStatus(unitStatus) {
    return api.patch(`${unitsUrl}/rent-status/${unitStatus[0].unittId}`, { rentStatus: unitStatus[0].value, unit: unitStatus[0].unitId }, config);
  },
  updateUnitRemarks(unitRemark) {
    return api.patch(`${unitsUrl}/unit-remark/${unitRemark._id}`, { comment: unitRemark.comment, commentStatus: unitRemark.commentStatus, commentDate: unitRemark.commentDate, commentCurrentUser: unitRemark.commentCurrentUser }, config);
  },
  downloadUnits(params) {
    return api.get(`/downloads/units`, { config, params })
  },
};
