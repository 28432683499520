<template>
	<v-container grid-list-md text-xs-center class="container">
		<v-layout row wrap>
			<v-flex md12 xs12 class="ma-5">
				<v-card class="card pa-4">
					<div class="header d-flex justify-space-between align-center">
						<div>
							<h1 class="secondary-text text-lg-left form-title">Units</h1>
							<!-- <h4 class="text-sm-left grey--text">Alle Woningen</h4> -->
						</div>

						<v-btn
								x-large
								color="blue-grey"
								class="white--text"
								@click="donwloadUnits()"
						>
							<v-icon small left dark>mdi-file-excel</v-icon>
							Download Units
						</v-btn>
					</div>

					<v-card flat :elevation="0">
						<v-card-title class="d-flex justify-space-between px-0">
              <!-- START FILTER HERE -->
              <filter-data-dialog-component
								class="filter--container"
								:acceptedFilters="acceptedFilters"
                :uniqueFilterStoreName="'filter$unitsList'"
								:showFilters="true"
								:showSearchBar="true"
                @filterData="setFilterOnList">
              </filter-data-dialog-component>

						</v-card-title>

						<v-data-table
								:headers="headers"
								:items="unitsV2"
								:options.sync="options"
								:server-items-length="totalUnits"
								:loading="loading"
								:footer-props="{'items-per-page-options': [5, 10, 15]}"
						>
							<template v-slot:body="{ items }">
								<tbody>
								<tr v-for="item in items">
									<td class="text-xs-left">{{item.brand['name']}}</td>
									<td class="text-xs-left">{{item.project['name']}}</td>
									<td class="text-xs-left">{{ item.type }}</td>
									<td class="text-xs-left">{{ item.name }}</td>
									<td class="align-end px-0">
										<v-spacer></v-spacer>
										<router-link :to="{ name: 'unitDetail', params: { id: item._id }}"
										             class="justify-center">
											<v-icon class="mr-2">
												mdi-home-edit-outline
											</v-icon>
										</router-link>
										<v-icon v-if="canEdit" @click="confirmDeleteDialogItem = item; confirmDeleteDialog = true">
											mdi-delete-outline
										</v-icon>
									</td>
								</tr>
								</tbody>
							</template>
							<template slot="no-data">
								<v-alert :value="true" color="grey" icon="mdi-warn">
									Sorry, geen gebruikers gevonden
								</v-alert>
							</template>
						</v-data-table>

						<div class="d-flex flex-row mt-10">
							<v-layout class="d-flex flex-column pr-8 ma-0">
								<v-file-input
										v-if="canEdit"
										outlined
										class="file-input mb-3"
										prepend-inner-icon="mdi-paperclip"
										prepend-icon=""
										placeholder="Units uploaden"
										v-model="file"
										hide-details
								></v-file-input>
								<v-btn class="" outlined :loading="loading" @click="uploadFile">Units opladen</v-btn>
							</v-layout>
						</div>

					</v-card>
				</v-card>
			</v-flex>
		</v-layout>
		<v-dialog v-model="confirmDeleteDialog" v-if="canEdit" max-width="400px">
			<confirm-delete v-if="confirmDeleteDialog" :item="confirmDeleteDialogItem"
			                @cancel="confirmDeleteDialogItem = null; confirmDeleteDialog = false"
			                @delete="deleteUnit"></confirm-delete>
		</v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { ACTIONS } from '../units.constants';
import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';
import { GETTERS } from '@/account/account.constants';
import { Roles } from '@/account/account.store';

import { DATA_FILTERS,DATA_FILTERS_ITEMS } from '@/components/data-filter/const.js'

import ConfirmDelete from '@components/dialog/confirm-delete';
import FilterDataDialogComponent from '../../../../components/data-filter/filter-data-dialog.component.vue';
import api from '../../../../services/backendApi';
import { debounce } from "debounce";


const unwind = require('javascript-unwind');
const UNIT_ACCEPTED_FILTERS = [DATA_FILTERS.START_DATE,DATA_FILTERS.BRANDS,DATA_FILTERS.PROJECTS,DATA_FILTERS.UNITS,DATA_FILTERS.UNIT_TYPES];

export default {
	components: { FilterDataDialogComponent, ConfirmDelete },
	data: () => ({
		acceptedFilters: UNIT_ACCEPTED_FILTERS,
    filterParams: {},
		getAllUnits: '',
		unitsV2: [],
		totalUnits: 0,
		loading: true,
		options: {},
		basicUrl: process.env.VUE_APP_API_URL,
		endpointFilter: null,
		endpointSort: null,
		endpointSearch: null,
		downloadUnitsURL: null,
		loading: false,
		file: null,
		selectedUser: null,
		confirmDeleteDialog: false,
		confirmDeleteDialogItem: null,
		headers: [
			{ text: 'Merken', value: 'brand.name', sortable: false  },
			{ text: 'Project', value: 'project.name', sortable: false  },
			{ text: 'Kamertype', value: 'type', sortable: false  },
			{ text: 'Nummer', value: 'name', sortable: false  },
			{ text: '', align: 'right', sortable: false },
		],
		labels: {
			'brand.name': { title: 'Bedrijf' },
			'prices.year': { title: 'Jaar' },
			'project.name': { title: 'Project' },
			'type': { title: 'Room type' },
			'name': { title: 'Room number' },
		},
	}),
	watch: {
		options: {
			handler () {
				this.getDataFromApi()
			},
			deep: true,
		},
	},
	mounted() {

	},
	computed: {
		...mapGetters({
			getRole: GETTERS.ROLE,
			getRights: GETTERS.RIGHTS
		}),
		csvData() {
			return this.units && this.units.length ? unwind(this.units, 'prices') : []
		},
		canEdit() {
			return this.getRights.UNITS_INFO.toLowerCase() === 'edit'
		}
	},
	methods: {
		...mapActions({
			makeToast: TOAST_ACTIONS.MAKE_TOAST,
			downloadUnits: ACTIONS.DOWNLOAD,
		}),
    setFilterOnList(filterDataParams){
      this.filterParams = filterDataParams;
      this.getDataFromApi();
    },
		uploadFile() {
			this.makeToast({
				message: `even geduld we updaten de units`,
				type: 'success',
			});
			this.loading = true;

			let formData = new FormData();
			formData.append('file', this.file, 'data.csv');

				api.post(`${this.basicUrl}/uploadData/unit-financial-data`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(() => {
					this.loading = false;
					this.file = null;
					this.makeToast({
						message: `Units werden succesvol opgeladen.`,
						type: 'success',
					});
				}, (error) => {
					this.makeToast({ message: error, type: 'error' });
				})
		},
		goToPaymentPage(item) {
			window.open(`${process.env.VUE_APP_URL}/${item.company}/payment-info?project=${item.project}&roomNumber=${item.roomNumber}`, '_blank');
		},
		deleteUnit(item) {
			api.delete(`${this.basicUrl}/units/${item._id}`).then(() => {
				this.makeToast({
					message: `Unit succesvol verwijderd`,
					type: 'success',
				});
				this.confirmDeleteDialog = false;
				this.confirmDeleteDialogItem = null;
				this.getDataFromApi()
			}, (error) => {
				this.makeToast({ message:'Er liep iets mis bij het verwijderen van de unit', type: 'error' });
			})
		},
		getDataFromApi () {
			this.loading = true
			this.unitsCall().then(data => {
				this.unitsV2 = data.items
				this.totalUnits = data.total
				this.loading = false
			})
		},
		unitsCall() {
			const config = { headers: { 'Content-Type': 'application/json' } };

			return new Promise((resolve, reject) => {
				const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const sortByFilter =null;

				if (sortBy.length === 1 && sortDesc.length === 1) {
					sortDesc[0] ?
					sortByFilter = `${sortBy[0]}:1` :
					sortByFilter = `${sortBy[0]}:-1`
				}
				let endpointValues = [this.endpointSort, this.endpointFilter]
				this.getAllUnits = ''

				for (let i of endpointValues) {
					if (i && i != null) {
						this.getAllUnits = this.getAllUnits + i;
					}
				}

				//api.get(`${this.basicUrl}/units?${this.getAllUnits}limit=${itemsPerPage}&skip=${page-1}`, config)
        const params = {
          skip: page-1,
          limit: itemsPerPage,
          soryBy: sortByFilter
        }

        api.get(`${this.basicUrl}/units`,{config,params:{...params,...this.filterParams}})
				.then(function (response) {
					let items = response.data.data;
					const total = response.data.totalCount
          resolve({	items,total}) ;
				})
			})
		},
		donwloadUnits() {
			const params = {...this.filterParams};
      console.log(params);
			const req = this.downloadUnits({params}).then(data => {
				let url = '';

				if (window.URL !== undefined && typeof window.URL === "function") {
					url = window.URL.createObjectURL(new Blob([data]));
				} else {
					const base64 = utf8_to_b64(data);
					url = `data:application/pdf;base64,${base64}`;
				}

				const element = document.createElement('a');

				element.href = url;
				element.setAttribute('download', 'download--units.csv');
				document.body.appendChild(element);

				element.click();
				element.remove();
			});

			return req;
		},
	},
};
</script>

<style lang="scss" scoped>

@import 'src/styles/global-filter.scss';

.search {
	max-width: 40rem;
	margin: 0 1rem 0 0;
}

.search-box {
	transform: scale(0.8);
	transform-origin: right;

	.v-text-field {
		padding: 3px;
		margin: 3px;
	}
}

.filter-select {
	max-width: 300px;
}

.container {
	margin: 0 15.625rem;
	max-width: calc(100vw - 300px);
}

.form-title {
	display: flex;
}

tr {
	background-color: #ffffff;
}

tr:nth-child(odd) {
	background-color: #f6f6f6;
}


</style>
