import api from '../../../services/backendApi';
import { MUTATIONS } from '@views/admin/users/users.constants';

const usersUrl = '/users';
const config = { headers: { 'Content-Type': 'application/json' } };

export default {
  getUsers(filter) {
    return api.get(`${usersUrl}`, { filter }, config);
  },
  getAllResets() {
    return api.get(`/password-reset`, config)
  },
  updateUser(user) {
    return api.patch(`${usersUrl}/${user._id}`,  user , config);
  },
  createUser(user) {
    return api.post(`${usersUrl}`, user , config);
  },
  removeUser(user) {
    return api.delete(`${usersUrl}/${user._id}`, config);
  },
  sendUserWelcomeMail(email) {
    return api.post(`/password-reset`, { email }, config)
  }
};
