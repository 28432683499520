import { ACTIONS, MUTATIONS } from './units.constants';
import unitsService from './units.service';

const payment = {
	state: {
		loading: false,
		units: [],
		detailUnit: {},
	},
	actions: {
		[ACTIONS.DOWNLOAD]({commit}, {params}) {
			return new Promise((resolve, reject) => {
					unitsService.downloadUnits(params).then(
							result => resolve(result.data),
							error => reject(error)
					);
			});
		},
		[ACTIONS.GET_ALL_UNITS]({ commit }, filter) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				unitsService.getUnits(filter)
					.then((result) => {
						commit(MUTATIONS.SET_ALL_UNITS, result.data.units);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.GET_RENT_UNITS]({ commit }) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				unitsService.getRentUnits()
					.then((result) => {
						commit(MUTATIONS.SET_RENT_UNITS, result.data.units);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.SET_UNIT_STATUS]({ commit, dispatch }, unitStatus) {
			return new Promise((resolve, reject) => {
				unitsService.updateUnitStatus(unitStatus)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_UNITS);
						resolve(result.data);
					}, (error) => {
						reject(error);
					});
			});
		},
		[ACTIONS.DELETE_UNITYEAR]({ commit, dispatch }, unit) {
			return new Promise((resolve, reject) => {
				unitsService.deleteUnitYear(unit)
					.then((result) => {
						// dispatch(ACTIONS.GET_ALL_UNITS);
						resolve(result.data);
					}, (error) => {
						reject(error);
					});
			});
		},
		[ACTIONS.GET_DETAIL_UNIT]({ commit }, id) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				unitsService.getUnitDetail(id)
					.then((result) => {
						commit(MUTATIONS.SET_DETAIL_UNIT, result.data);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},[ACTIONS.GET_SUBSCRIPTION_PLANS]({ commit }, file) {
			return new Promise((resolve, reject) => {
				unitsService.getSubscriptionPlans()
					.then((result) => {
						commit(MUTATIONS.SET_SUBSCRIPTION_PLANS, result.data),
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
			});
		}, [ACTIONS.UPDATE_UNIT]({ commit }, unit) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				unitsService.updateUnit(unit)
					.then((result) => {
						commit(MUTATIONS.SET_DETAIL_UNIT, result.data);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		}, [ACTIONS.UPLOAD_FILE]({ commit }, file) {
			return new Promise((resolve, reject) => {
				unitsService.uploadData(file)
					.then((result) => {
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
			});
		},
		[ACTIONS.UPLOAD_FILEPRICES]({ commit }, file) {
			return new Promise((resolve, reject) => {
				unitsService.uploadPricesData(file)
					.then((result) => {
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
			});
		},
		[ACTIONS.DELETE_UNIT]({ commit, dispatch }, unit) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				unitsService.deleteUnit(unit)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_UNITS);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.UPDATE_REMARKS]({ commit, dispatch }, unitRemark) {
			return new Promise((resolve, reject) => {
					unitsService.updateUnitRemarks(unitRemark)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_UNITS);
						resolve(result.data);
					}, (error) => {
						reject(error);
					});
			});
		},
	},
	mutations: {
		[MUTATIONS.SET_ALL_UNITS](state, units) {
			state.units = units;
		},
		[MUTATIONS.SET_RENT_UNITS](state, units) {
			state.units = units;
		},
		[MUTATIONS.SET_DETAIL_UNIT](state, unit) {
			state.detailUnit = unit;
		},
		[MUTATIONS.SET_IS_LOADING](state, loadingState) {
			state.loading = loadingState;
		},
		[MUTATIONS.SET_SUBSCRIPTION_PLANS](state, subscriptionPlans) {
			state.subscriptionPlans = subscriptionPlans;
		},
	},
};


export default payment;
