export const MODULE_NAME = 'users';

export const ACTIONS = {
	GET_ALL_USERS: `${MODULE_NAME}/GET_ALL_USERS`,
	GET_ALL_RESETS: `${MODULE_NAME}/GET_ALL_RESETS`,
	UPDATE_USER: `${MODULE_NAME}/UPDATE_USER`,
	CREATE_USER: `${MODULE_NAME}/CREATE_USER`,
	REMOVE_USER: `${MODULE_NAME}/REMOVE_USER`,
	SEND_USER_WELCOME_MAIL: `${MODULE_NAME}/SEND_USER_WELCOME_MAIL`,
};


export const MUTATIONS = {
	SET_ALL_USERS: `${MODULE_NAME}/SET_ALL_USERS`,
	SET_ALL_RESETS: `${MODULE_NAME}/SET_ALL_RESETS`,
	SET_IS_LOADING: `${MODULE_NAME}/SET_IS_LOADING`,
};
