<template>
	<div grid-list-md text-xs-center class="information-tab-container">
		<v-layout row wrap>
			<v-flex md3 xs12>

				<v-card class="tab-card listbox">
					<v-list dense>
						<v-list-item avatar @click="active = 'main'" :class="active === 'main' ? 'active' : ''">
							<v-list-item-action>
								<v-icon color="grey">mdi-fingerprint</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title v-text="'Algemene gegevens'"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item avatar @click="active = 'price'" :class="active === 'price' ? 'active' : ''" v-if="!canSeePrices">
							<v-list-item-action>
								<v-icon color="grey">mdi-cash</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title v-text="'Prijzen'"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<!-- <v-list-item avatar @click="active = 'company'" :class="active === 'company' ? 'active' : ''" v-if="!canSeePrices">
							<v-list-item-action>
								<v-icon color="grey">mdi-account</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title v-text="'Eigenaars'"></v-list-item-title>
							</v-list-item-content>
						</v-list-item> -->

					</v-list>
				</v-card>

			</v-flex>
			<v-flex md9 xs12>
				<v-card class="tab-card" v-if="active === 'main'">
					<v-form
							ref="form"
							:disabled="!edit"
							v-model="valid"
							lazy-validation
					>
						<v-text-field
								v-model="unit.project.name"
								label="Project"
								required
						></v-text-field>

						<v-text-field
								v-model="unit.type"
								label="Kamer Type"
								required
						></v-text-field>
						<v-text-field
								v-model="unit.name"
								label="Kamer Nummer"
								required
						></v-text-field>
            <v-text-field v-if="unit.owner"
								v-model="unit.owner.name"
								label="Huidige eigenaar"
								required
						></v-text-field>
            <v-text-field v-else
                v-model="tempNoContractsYet"
								label="Huidige eigenaar"
								required
						></v-text-field>

						<!-- DISABLED UNTIL FURTHER NOTICE -->

						<!-- <v-btn
								:disabled="!valid"
								color="success"
								class="mr-4"
								@click="$emit('save', unit)"
						>
							Save
						</v-btn> -->
					</v-form>
				</v-card>
				<v-card class="tab-card" v-if="active === 'price' ">
					<price-panel @save="$emit('save', unit)" :unit="unit"></price-panel>
				</v-card>

				<v-card class="tab-card" v-if="active === 'company' ">
					<company-panel :unit="unit"></company-panel>
				</v-card>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
	import PricePanel from './price/price-panel';
	import CompanyPanel from './company/company';

	import { GETTERS } from '@/account/account.constants';
	import { mapGetters } from 'vuex';

	export default {
		name: 'information-tab',
		components: { PricePanel, CompanyPanel },
		props: {
			unit: {
				type: Object,
			},
			edit: {
				type: Boolean,
			}
		},
		data() {
			return {
				valid: false,
				active: 'main',
        tempNoContractsYet: 'Nog niet beschikbaar',
				items: [
					{ key: 'main', icon: 'mdi-fingerprint', title: 'Algemene gegevens' },
					{ key: 'price', icon: 'mdi-cash', title: 'Prijzen', admin: true },
					// { key: 'company', icon: 'mdi-account', title: 'Eigenaars'},
				],
			};
		},
		mounted() {

		},
		computed: {
			...mapGetters({
				getRole: GETTERS.ROLE,
				getRights: GETTERS.RIGHTS
			}),
			canSeePrices() {
				return this.getRights.UNITS_PRICES.toLowerCase() === 'none'
			},
		},
	};
</script>

<style lang="scss" scoped>
	.information-tab-container {
		margin-left: 0;
	}

	.excel {
		width: 100%;
	}

	.tab-card {
		margin-right: 5px;
		padding: 25px !important;
		border-radius: 7px !important;

		&.listbox {
			padding: 10px 0 !important;
		}

		.active {
			border-left: #150735 3px solid;
			background-color: rgba(152, 144, 146, 0.2);

			i {
				color: #150735 !important;
			}
		}

		>>> .v-list__tile__action {
			width: 25px;
			min-width: 30px;
			font-weight: 500;
		}
	}
</style>
