<template>
	<div>
		<v-layout row wrap>
			<v-flex md12 xs12>
				<v-card class="card pa-4">
					<div class="header d-flex justify-space-between align-center">
						<h1 class="secondary-text text-lg-left form-title">Overzicht gebruikers</h1>
						<v-btn x-large color="blue-grey" class="white--text" v-if="canEdit" @click="dialog = true">
							<v-icon small left dark>mdi-plus</v-icon>
							Voeg gebruiker toe
						</v-btn>
					</div>

					<v-card flat :elevation="0">
						<v-card-title class="d-flex justify-space-between px-0">
              <!-- START FILTER HERE -->
              <filter-data-dialog-component
								class="filter--container"
								:acceptedFilters="acceptedFilters"
                :uniqueFilterStoreName="'filter$usersList'"
								:showFilters="false"
								:showSearchBar="true"
                @filterData="setFilterOnList">
              </filter-data-dialog-component>
						</v-card-title>

						<v-data-table
								:headers="headers"
								:items="usersV2"
								:options.sync="options"
								:server-items-length="totalUsers"
								:footer-props="{'items-per-page-options': [5, 10, 15]}"
								:loading="loading"
								item-key="email"
						>
							<template v-slot:body="{ items }">
								<tbody>
								<tr v-for="item in items">
									<td class="justify-center">
										<v-icon v-if="item.active"
												class="mr-2" color="green">
											mdi-checkbox-marked-circle-outline
										</v-icon>
										<v-icon v-else class="mr-2" color="orange">
											mdi-close-circle-outline
										</v-icon>
									</td>
									<td class="text-xs-left">{{ item.firstName }} {{ item.lastName }}</td>
									<td class="text-xs-left">{{ item.email }}</td>
									<td class="text-xs-left text-capitalize">{{ item.role }}</td>
									<td class="d-flex justify-center px-0">
										<v-icon v-if="canEdit" @click="openUserEdit(item)">
											mdi-account-edit-outline
										</v-icon>
									</td>
									<td class="text-xs-left text-capitalize small-col">
										<v-btn v-if="!item.active && canEdit" :disabled="checkIfUserHasReset(item)"
										       @click="resendInvitationMail(item)" small color="primary">
											herzend welkom mail
										</v-btn>
									</td>
								</tr>
								</tbody>
							</template>
							<template slot="no-data">
								<v-alert :value="true" color="grey" icon="mdi-warn">
									Sorry, geen gebruikers gevonden
								</v-alert>
							</template>
						</v-data-table>
					</v-card>
				</v-card>
			</v-flex>
		</v-layout>
		<v-dialog v-model="dialog" max-width="700px">
			<user-edit :editedItem="editedItem" @save="save" @close="close" @delete="deleteUser"></user-edit>
		</v-dialog>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
	import { ACTIONS } from '../users.constants';
	import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';
	import UserEdit from '../detail/user-edit';
import { GETTERS } from '@/account/account.constants';
import { Roles } from '@/account/account.store';
import api from '../../../../services/backendApi';
import { debounce } from "debounce";
import FilterDataDialogComponent from '../../../../components/data-filter/filter-data-dialog.component.vue';

	export default {
		components: { UserEdit, FilterDataDialogComponent },
		data: () => ({
			acceptedFilters: [],
			filterParams: {},
			getAllUsers: '',
			usersV2: [],
			totalUsers: 0,
			loading: true,
			options: {},
			basicUrl: process.env.VUE_APP_API_URL,
			usersFilter: [],
			endpointFilter: null,
			endpointSort: null,
			endpointSearch: null,
			endpointData: null,
			dialog: false,
			editedIndex: -1,
			editedItem: {
				firstName: '',
				lastName: '',
				email: '',
				role: '',
			},
			selectedUser: null,
			headers: [
				{ text: 'Status', sortable: false, align: 'left', },
				{ text: 'Naam', value: 'name' },
				{ text: 'Email', value: 'email' },
				{ text: 'Rol', value: 'role' },
				{ text: 'User bewerken', align: 'center', sortable: false },
				{ text: 'Herstuur uitnodiging', align: 'left', sortable: false },
			],
		}),
		watch: {
			options: {
				handler () {
					this.getDataFromApi()
				},
				deep: true,
			},
		},
		mounted() {

		},
		computed: {
			...mapState({
				users: state => state.users.users,
				resets: state => state.users.resets,
			}),
			...mapGetters({
				role: GETTERS.ROLE,
				getRights: GETTERS.RIGHTS
			}),
			canEdit() {
				return this.getRights.SETTINGS_USERS.toLowerCase() === 'edit' 
			}
		},
		methods: {
			...mapActions({
				getUsers: ACTIONS.GET_ALL_USERS,
				updateUser: ACTIONS.UPDATE_USER,
				createUser: ACTIONS.CREATE_USER,
				removeUser: ACTIONS.REMOVE_USER,
				makeToast: TOAST_ACTIONS.MAKE_TOAST,
				sendMail: ACTIONS.SEND_USER_WELCOME_MAIL,
			}),
			setFilterOnList(filterDataParams){
				this.filterParams = filterDataParams;
				this.getDataFromApi();
			},
			openUserEdit(item) {
				this.editedItem = Object.assign(this.editedItem, item);
				this.dialog = true
			},
			checkIfUserHasReset(user) {
				return this.resets && !!this.resets.find(us => us.user._id === user._id);
			},
			resendInvitationMail(item) {
				this.sendMail(item.email).then(() => {
					this.makeToast({
						message: `${item.name} heeft succesvol nieuwe mail ontvangen`,
						type: 'success',
					});
				}, (error) => {
					this.makeToast({ message: error, type: 'error' });
				});
			},
			deleteUser(user) {
				console.log('deleteUser')
				this.removeUser(user).then(() => {
					this.makeToast({
							message: `Gebruiker succesvol verwijderd`,
							type: 'success',
						});
						
						this.close();
						this.getDataFromApi();
				}, (error) => {
					this.makeToast({ message:'Er liep iets mis bij het verwijderen van de gebruiker', type: 'error' });
				})
			},
			close() {
				this.dialog = false;
				this.$nextTick(() => {
					this.editedItem = {
						firstName: '',
						lastName: '',
						email: '',
						role: 'coach'
					};
					this.editedIndex = -1
				})
			},
			save(user) {
				if (user._id) {
					this.updateUser(user).then(() => {
						this.makeToast({
								message: `Gebruiker succesvol aangepast`,
								type: 'success',
							});
							
							this.close();
							this.getDataFromApi();
					}, (error) => {
						this.makeToast({ message:'Er liep iets mis bij het bewerken van de gebruiker', type: 'error' });
					})
				} else {
					this.createUser(user).then(() => {
						this.makeToast({
								message: `Gebruiker succesvol aangepast`,
								type: 'success',
							});
							
							this.close();
							this.getDataFromApi();
					}, (error) => {
						this.makeToast({ message:'Er liep iets mis bij het opslaan van de gebruiker', type: 'error' });
					})
				}
			},

			getDataFromApi () {
				this.loading = true
				this.usersCall().then(data => {
					this.usersV2 = data.items
					this.totalUsers = data.total
					this.loading = false
				})
			},

			usersCall () {
				const config = { headers: { 'Content-Type': 'application/json' } };

				return new Promise((resolve, reject) => {
					const { sortBy, sortDesc, page, itemsPerPage } = this.options

					if (sortBy.length === 1 && sortDesc.length === 1) {
						sortDesc[0] ?
						this.filterParams.sortBy = `${sortBy[0]}:1` :
						this.filterParams.sortBy = `${sortBy[0]}:-1`
					} else {
						this.filterParams.sortBy = null
					}

				const params = {
          skip: page-1,
          limit: itemsPerPage,
          ... this.filterParams
        };

					api.get(`${this.basicUrl}/users`, {config, params})
					.then(function (response) {
						let items = response.data.data;
						const total = response.data.totalCount
            resolve({	items,total})
					})
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	.search-box {
		transform: scale(0.8);
		transform-origin: right;

		.v-text-field {
			padding: 3px;
			margin: 3px;
		}
	}

	.small-col {
		max-width: 60px;
	}
	.x-small-col {
		max-width: 5px;
	}

	.container {
		margin-left: 250px;
		margin-right: 250px;
		max-width: calc(100vw - 300px) !important;
	}

	.form-title {
		display: flex;
	}

	tr {
		background-color: #ffffff;
	}

	tr:nth-child(odd) {
		background-color: #f6f6f6;
	}

	.filter {
		&--container {
			width: 100%;
		}
	}
</style>
