import { ACTIONS, MUTATIONS } from './users.constants';
import usersService from './users.service';

const payment = {
	state: {
		loading: false,
		users: [],
		resets: []
	},
	actions: {
		[ACTIONS.GET_ALL_USERS]({ commit, dispatch }, filter) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				usersService.getUsers(filter)
					.then((result) => {
						commit(MUTATIONS.SET_ALL_USERS, result.data.rows);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						dispatch(ACTIONS.GET_ALL_RESETS);
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.GET_ALL_RESETS]({ commit }) {
			usersService.getAllResets()
				.then((response) => {
					commit(MUTATIONS.SET_ALL_RESETS, response.data);
				}, (error) => {
					reject(error);
				});
		},
		[ACTIONS.UPDATE_USER]({ commit, dispatch }, user) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				usersService.updateUser(user)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_USERS);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.CREATE_USER]({ commit, dispatch }, user) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				usersService.createUser(user)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_USERS);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.REMOVE_USER]({ commit, dispatch }, user) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				usersService.removeUser(user)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_USERS);
						resolve(result.data);

						return usersService.getUsers();
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.SEND_USER_WELCOME_MAIL]({ commit, dispatch }, email) {
			return new Promise((resolve, reject) => {
				usersService.sendUserWelcomeMail(email)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_RESETS);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
	},
	mutations: {
		[MUTATIONS.SET_ALL_USERS](state, users) {
			state.users = users;
		},
		[MUTATIONS.SET_ALL_RESETS](state, resets) {
			state.resets = resets;
		},
		[MUTATIONS.SET_IS_LOADING](state, loadingState) {
			state.loading = loadingState;
		},
	},
};


export default payment;
