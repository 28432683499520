export const MODULE_NAME = 'units';

export const ACTIONS = {
	GET_ALL_UNITS: `${MODULE_NAME}/GET_ALL_UNITS`,
	GET_RENT_UNITS: `${MODULE_NAME}/GET_RENT_UNITS`,
	GET_DETAIL_UNIT: `${MODULE_NAME}/GET_DETAIL_UNIT`,
	DELETE_UNIT: `${MODULE_NAME}/DELETE_UNIT`,
	UPDATE_UNIT: `${MODULE_NAME}/UPDATE_UNIT`,
	UPLOAD_FILE: `${MODULE_NAME}/UPLOAD_FILE`,
	UPLOAD_FILEPRICES: `${MODULE_NAME}/UPLOAD_FILEPRICES`,
	SET_UNIT_STATUS: `${MODULE_NAME}/SET_UNIT_STATUS`,
	UPDATE_REMARKS: `${MODULE_NAME}/UPDATE_REMARKS`,
	DELETE_UNITYEAR: `${MODULE_NAME}/DELETE_UNITYEAR`,
	DOWNLOAD: `${MODULE_NAME}/DOWNLOAD`,
};


export const MUTATIONS = {
	SET_ALL_UNITS: `${MODULE_NAME}/SET_ALL_UNITS`,
	SET_RENT_UNITS: `${MODULE_NAME}/SET_RENT_UNITS`,
	SET_DETAIL_UNIT: `${MODULE_NAME}/SET_DETAIL_UNIT`,
	SET_IS_LOADING: `${MODULE_NAME}/SET_IS_LOADING`,
};
