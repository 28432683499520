<template>
	<v-card>
		<v-card-title>
			<span class="headline">Prijs aanpassen</span>
		</v-card-title>

		<v-card-text v-if="editedItem">
			<v-container class="price-edit-container">
				<v-row class="mt-6">

					<v-col
						cols="12"
						lg="6"
						class="py-0"
					>
						<v-menu
							ref="menu1"
							v-model="menu1"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="dateFormatted"
									label="Start datum"
									persistent-hint
									prepend-icon="mdi-calendar"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="date"
								no-title
								@input="menu1 = false"
							></v-date-picker>
						</v-menu>
					</v-col>


					<v-col
						cols="12"
						lg="6"
						class="py-0"
					>
						<v-menu
							ref="menu2"
							v-model="menu2"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="endDateFormatted"
									label="Eind datum"
									persistent-hint
									prepend-icon="mdi-calendar"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="endDate"
								no-title
								@input="menu2 = false"
							></v-date-picker>
						</v-menu>
					</v-col>
				</v-row>


				<v-row v-for="payment in payments" :key="payment.id">
					<template v-if="editedItem[payment.id]">
						<v-col cols="12" sm="12" md="12" >
							<v-switch
									v-model="editedItem[payment.id].status"
									:value="editedItem[payment.id].status"
									:label="payment.label"
									color="black"
									hide-details
							></v-switch>
						</v-col>

						<template v-if="editedItem[payment.id].status">
							<v-col cols="12" sm="12" md="12">
								<v-select
										outlined
										v-model="editedItem[payment.id].paymentMethod"
										label="Betalingsmethode"
										:items="paymentTypes"
										item-value="id"
										item-text="label"
										required
										@change="editedItem[payment.id].company = getCompanies(editedItem[payment.id].paymentMethod)[0]"
								></v-select>
							</v-col>
							<v-col cols="12" :sm="payment.id === 'rent' ? 12 : 6" :md="payment.id === 'rent' ? 12 : 6">
								<v-autocomplete
										outlined
										v-model="editedItem[payment.id].companyId"
										@change="onCompanyChange(editedItem[payment.id].companyId, payment.id)"
										label="Company / Eigenaar"
										:items="getCompanies(editedItem[payment.id].paymentMethod)"
										item-value="_id"
										item-text="name"
										required
								></v-autocomplete>
							</v-col>
							<v-col cols="12" sm="6" md="6" v-if="payment.id === 'rent'">
								<v-text-field
										outlined
										v-model.number="editedItem[payment.id].stayAmount"
										label="Bedrag blijver"
										type="number"
										required
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" >
								<v-text-field
										outlined
										v-model.number="editedItem[payment.id].amount"
										:label="payment.id =='rent' ?'Bedrag nieuwkomer':'Bedrag'"
										type="number"
										required
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="6" md="6" v-if="editedItem[payment.id].paymentMethod !== 'recurring' && editedItem[payment.id].paymentMethod !== 'oneTimePayment'">
								<v-text-field
										outlined
										disabled
										v-if="editedItem[payment.id].bankAccount"
										v-model="editedItem[payment.id].bankAccount"
										label="Rekeningnummer"
										required
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6" v-if="editedItem[payment.id].paymentMethod !== 'recurring' && editedItem[payment.id].paymentMethod !== 'oneTimePayment'">
								<v-text-field
										v-if="editedItem[payment.id].currentOwner"
										outlined
										disabled
										v-model="editedItem[payment.id].currentOwner"
										label="Naam rekeninghouder"
										required
								></v-text-field>
							</v-col>



						</template>
					</template>
				</v-row>
			</v-container>
		</v-card-text>

		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
					color="blue darken-1"
					text
					@click="$emit('close')"
			>
				Annuleren
			</v-btn>
			<v-btn
					color="blue darken-1"
					text
					@click="saveClicked"
			>
					<!-- @click="$emit('save')" -->
				Opslaan
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	name: "price-edit",
	props: {
		editedItem: Object,
		companies: Array,
		subscriptionPlans: Array,
		recurringCompanies: Array,
		addPriceYear: Boolean,
    newStartDate: String
	},
	data: vm => ({
		basicUrl: process.env.VUE_APP_API_URL,
		rent: {
			visible: false,
		},
		paymentTypes: [
			{ id: 'onetimetask', label: 'Eenmalige betaling' },
			{ id: 'recurring', label: 'Domiciliëring' },
			{ id: 'ongoingtask', label: 'Doorlopende opdracht' },
			{ id: 'onetimepayment', label: 'Eenmalige betaling via Mollie' },
		],
		payments: [
			{ id: 'rent', label: 'Huur' },
			{ id: 'deposit', label: 'Huurwaarborg' },
			{ id: 'egwi', label: 'Egwi' },
			{ id: 'studentTax', label: 'Studenten taks' },
			{ id: 'inventory', label: 'Plaatsbeschrijving' },
		],

		date: null,
		dateFormatted: null,
		menu1: false,
		endDate: null,
		endDateFormatted: null,
		menu2: false,
	}),
	watch: {
		dialog(val) {
			val || this.close()
		},
		date(val) {
			this.dateFormatted = this.formatDate(this.date)
		},
		endDate(val) {
			this.endDateFormatted = this.formatDate(this.endDate)
		},
	},
	mounted() {
		this.date =  this.editedItem.startDate || this.newStartDate ;
		this.endDate = this.editedItem.endDate;
	},
	computed: {
		computedDateFormatted () {
			return this.formatDate(this.date), this.formatDate(this.endDate)
		},
		mollieCompanies() {
			return this.companies.filter(e => !!e.key)
		},
		eCurringCompanies() {
			return this.recurringCompanies.filter(e => !!e.eCurringKey)
		},
		companiesWithAccountDetails() {
			return this.companies.filter(e => e.bankAccount && e.name)
		},
		mappedSubscriptionPlans() {
			return this.subscriptionPlans.map(company => ({
				...company,
				data: company.data.map(sub => ({
					id: sub.attributes.id,
					name: sub.attributes.name,
				}))
			}));
		},
	},
	methods: {
		formatDate(date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		onCompanyChange(companyId, payment) {
			let company = this.companies.find(c => c._id === companyId);

			this.editedItem[payment].bankAccount = company.bankAccount;
			this.editedItem[payment].currentOwner = company.name;
			this.editedItem[payment].companyId = company._id;
		},
		getCompanies(paymentType) {
			switch (paymentType) {
				case 'onetimepayment':
					return this.mollieCompanies;
				case 'recurring':
					return this.eCurringCompanies;
				default:
					return this.companiesWithAccountDetails
			}
		},
		getMappedSubscriptions(owner) {
			if (owner != undefined) {
				if (this.mappedSubscriptionPlans.filter(e => e.company === owner).length != 0) {
					return this.mappedSubscriptionPlans.filter(e => e.company === owner)[0].data.sort((a, b) => {return a.name-b.name});
				}

				return
			}
		},
		saveClicked() {
			this.editedItem.startDate = this.date;
			this.editedItem.endDate = this.endDate;

			this.$emit('save', this.addPriceYear);
		},
	}
}
</script>

<style scoped>
.container {
	margin-left: 0 !important;
}

.price-edit-container {
	padding-top: 0;
	margin: 0;
}

</style>
