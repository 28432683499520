<template>
  <v-card class="profile" v-if="unit">

      <v-layout row wrap>
        <v-flex md4 xs12>
          <div class="profile_main">
            <div class="profile_main-info">
              <h2 class="grey--text text-lg-left text--darken-3 mt-3">{{unit.project['name']}}</h2>
              <h4 class="grey--text text-lg-left">{{unit.type}}</h4>
            </div>
          </div>
        </v-flex>
        <v-divider class="mx-3" inset vertical></v-divider>
        <v-flex md4 xs12>
          <div class="profile_contact">
            <div class="profile_contact-row mt-3">
              <v-icon>mdi-folder-pound-outline</v-icon>
              <h3 class="text-lg-left"> {{unit.name}}</h3>
            </div>
	          <div class="profile_contact-row mt-3">
		          <v-icon>mdi-floor-plan</v-icon>
		          <h3 class="text-lg-left"> {{unit.type}}</h3>
	          </div>
          </div>
        </v-flex>

        <v-divider class="mx-3" inset vertical></v-divider>

        <v-flex md3 xs12>
          <div class="profile_address">
            <div class="profile_address-row">
              <h4 class="grey--text mt-3 text-lg-left">ADRES</h4>
              <div v-if="unit.project.address">
                <h4 class="text-lg-left">{{unit.project.address['street']}}</h4>
                <h4 class="text-lg-left">{{unit.project.address['zip']}} {{unit.project.address['city']}}</h4>
              </div>
              <div v-else>
                <h4>Adres ontbreekt</h4>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    <slot name="tabs"></slot>
  </v-card>
</template>

<script>
  export default {
    name: 'unit-card',
    props: {
      unit: {
        type: Object,
      },
    },
    data() {
      return {
        dialog: false,
      };
    }
  };
</script>

<style lang="scss" scoped>

  .profile {
    padding: 10px !important;
    border-radius: 7px !important;
  }

  .profile_main {
    display: flex;
    flex-direction: row;

    .profile_main-info {
      margin: 1.5rem 0;
    }
  }

  .profile_contact {
    display: flex;
    flex-direction: column;

    .profile_contact-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;

      h3 {
        padding-left: 1.5rem;
      }
    }
  }

  .profile_address {
    display: flex;
    flex-direction: column;

    .profile_address-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;

      h3 {
        padding-left: 1.5rem;
      }
    }
  }


</style>
