<template>
	<v-container grid-list-md text-xs-center class="">
		<div class="grid text-xs-center">
			<unit-card :unit="unit" class="v-card-profile">
				<template v-slot:tabs>
					<v-tabs
							class="tabs"
							v-model="active"
							show-arrows
					>
						<v-tabs-slider></v-tabs-slider>
						<v-tab href="#basis-gegevens" active-class="active">Gegevens</v-tab>
					</v-tabs>
				</template>
			</unit-card>

			<div id="profile-form">
				<v-tabs-items v-model="active">
					<v-tab-item :value="'basis-gegevens'">
						<information-tab :unit="unit" :edit="isAdmin" @save="saveUnit"></information-tab>
					</v-tab-item>
				</v-tabs-items>
			</div>
		</div>
	</v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
	import { ACTIONS } from '../units.constants';
	import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';
	import InformationTab from '../components/information-tab';
	import UnitCard from '../components/unit-card';
import { GETTERS } from '@/account/account.constants';
import { Roles } from '@/account/account.store';
import api from '../../../../services/backendApi';

	export default {
		name: 'Profile',
		components: { InformationTab, UnitCard },
		computed: {
			// ...mapState({
			// 	unit: state => state.units.detailUnit,
			// }),
			...mapGetters({
				role: GETTERS.ROLE,
			}),
			isAdmin() {
				return this.role === Roles.ADMIN;
			}
		},
		data() {
			return {
				active: null,
				basicUrl: process.env.VUE_APP_API_URL,
				unit: null,
			};
		},
		mounted() {
			const config = { headers: { 'Content-Type': 'application/json' } };
			api
				.get(`${this.basicUrl}/units/${this.$route.params.id}`, config)
				.then(response => (this.unit = response.data.data))
		},
		methods: {
			...mapActions({
				updateUnit: ACTIONS.UPDATE_UNIT,
				makeToast: TOAST_ACTIONS.MAKE_TOAST,
			}),
			saveUnit(unit) {
				this.updateUnit(unit).then(() => {
				  this.makeToast({
					message: `${unit.project} ${unit.roomNumber} succesvol opgeslagen`,
					type: 'success',
				  });
				}, (error) => {
				  this.makeToast({ message: error, type: 'error' });
				});
			},
		},
	};
</script>

<style lang="scss">
	.grid {
		display: flex;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		flex-direction: column;
		margin-bottom: 20px;
		padding: 10px 25px;
		border-radius: 4px;
	}

	#profile-form {
		margin-top: 10px;
	}

	.container {
		padding: 0;
		padding-top: 5rem !important;
		margin-left: 250px;
		margin-right: 250px;
		max-width: calc(100vw - 300px)!important;
	}

	.v-tabs-items {
		background-color: transparent!important;
	}

	.v-tabs__item {
		&.active {
			color: #150735;
			opacity: 1 !important;
		}
	}
</style>
